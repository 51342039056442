<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column">
            <label>Línea</label>
            <div class="select is-fullwidth">
              <select v-model="lineId" name="lineId">
                <option selected value="">Todos</option>
                <option v-for="line in linesList" :key="line.id" :value="line.id">
                  {{ line.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column">
            <label>Marca</label>
            <div class="select is-fullwidth">
              <select v-model="brandId" name="brandId">
                <option selected value="">Todos</option>
                <option v-for="brand in brandsList" :key="brand.id" :value="brand.id">
                  {{ brand.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column">
            <label>Estatus</label>
            <div class="select is-fullwidth">
              <select v-model="statusSelect" name="statusSelect">
                <option selected value="all">Todos</option>
                <option v-for="status in statusList" :key="status.id" :value="status.value">
                  {{ status.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="column is-3">
            <label>Buscador</label>
            <a-input-search
              type="text"
              v-model="searchText"
              allow-clear
              @search="searchProducts"
            />
          </div>
          <div class="column is-12 has-text-right">
            <button class="button button_micro_dark" @click="getProducts">
              Buscar
            </button>
          </div>
          <div class="column is-12 is_grid">
            <div class="table-container">
              <base-table
                :columns="columnsData"
                :data="productsList.data"
                :loading="loading"
              >
                <div slot="status" slot-scope="scope">
                  <p :class="[ scope.row.active ? 'text_green' : 'text_error' ]">
                    {{ scope.row.active ? 'Activo' : 'Inactivo' }}
                  </p>
                </div>
              </base-table>
            </div>
          </div>
          <div class="column is-12">
            <base-pagination
              :page="page"
              :pages="pages"
              :action="paginator"
              :loading="loading"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

const DEFAULT_DISPLAY_SIZE = 50

export default {
  name: 'MainProducts',
  components: {
    BaseTable: () => import('@/components/UI/BaseTable.vue'),
    BasePagination: () => import('@/components/UI/BasePagination.vue')
  },
  computed: {
    ...mapGetters(['productsList', 'brandsList', 'linesList', 'productsLoaderSpinner']),
    pages () {
      if (this.productsList) {
        return this.productsList.info.pages ? this.productsList.info.pages : 0
      } else {
        return 0
      }
    },
    loading () {
      return !!this.productsLoaderSpinner
    }
  },
  data () {
    return {
      page: 1,
      lineId: '',
      brandId: '',
      sectorId: '',
      statusSelect: 'all',
      searchText: '',
      sectorList: [
        { id: 1, name: 'Opción 1' },
        { id: 2, name: 'Opción 2' },
        { id: 3, name: 'Opción 3' }
      ],
      statusList: [
        { id: 1, name: 'Inactivo', value: false },
        { id: 2, name: 'Activo', value: true }
      ],
      columnsData: [
        { id: 'id', header: 'ID' },
        {
          id: 'product_name',
          header: 'Nombre del producto',
          link: true,
          type: 'link',
          class: 'link',
          route: (row) => ({ name: 'detalle-producto', params: { productId: row.id } })
        },
        { id: 'mi_key', header: 'Clave MI', accessor: (row) => row.mi_key && row.mi_key !== 'null' ? row.mi_key : '--' },
        { id: 'line', header: 'Línea' },
        { id: 'brand', header: 'Marca' },
        { id: 'created_at', header: 'Fecha de publicación', accessor: (row) => moment(row.created_at).format('DD/MMM/YYYY') },
        { id: 'status', name: 'status', header: 'Estatus' }
      ]
    }
  },
  mounted () {
    if (!this.linesList.length) {
      this.getAllProductLinesCatalog()
    }
    if (!this.brandsList.length) {
      this.getAllBrandsCatalog()
    }
    this.getProducts()
  },
  methods: {
    ...mapActions(['getProductsList', 'getProductLinesCatalog', 'getProductBrandCatalog', 'getAllProductLinesCatalog', 'getAllBrandsCatalog']),
    getProducts () {
      const { page, lineId, brandId, statusSelect, searchText } = this
      const options = {
        page,
        limit: DEFAULT_DISPLAY_SIZE,
        lineId,
        brandId,
        status: statusSelect,
        searchText
      }
      this.getProductsList(options)
    },
    searchProducts (value) {
      this.page = 1
      if (value.length >= 3) {
        this.searchText = value
      }
      this.getProducts()
    },
    paginator (page) {
      this.page = page
      this.getProducts()
    }
  }
}
</script>
